import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getSelectedLng } from '../utils/utils';
import { LANGUAGE_RUS } from '../constant/constants';

i18n.use(initReactI18next).init({
  resources: {
    rus: {
      translation: {
        flowmeterCnt: 'Количество счетчиков',
        iccid: 'ICCID',

        // Расшифровка ошибок
        errFlowMax: '1 - Превышен максимальный расход',
        errTempLimit: '2 - Температура вышла за пределы контроля',
        errMagnet: '3 - Обнаружено воздействие магнита',
        errCalibErr: '4 - Ошибка калибровки канала температуры',
        errBox: '5 - Открыт основной отсек',
        errAdc: '6 - Отсутствует измерительный модуль',
        errBox2: '7 - Открыт батарейный отсек',
        errMinFlow: '8 - Расход ниже минимального',
        errEepromFlash: '9 - Ошибка памяти EEPROM/FLASH',
        err10: '10 - Перезагрузка прибора',
        err11: '11 - Настройки изменены',
        err12: '12 - Изменен пароль',
        err13: '13 - Дата и время изменены',
        err14: '14 - Низкий заряд батареи',
        err15: '15 - Перезагрузка прибора через сторожевой таймер',
        err16: '16 - Системные настройки изменены',
        err17: '17 - Уровень доступа изменен на Не авторизован',
        err18: '18 - Уровень доступа изменен на Пользователь',
        err19: '19 - Уровень доступа изменен на Поставщик',
        err20: '20 - Уровень доступа изменен на Сервисный центр',
        err21: '21 - Уровень доступа изменен на Производитель',
        err22: '22 - Регистрационный код лицензии изменен',
        err23: '23 - Старт обновления прошивки',
        err24: '24 - Завершение обновления прошивки',
        err25: '25 - Команда на открытие задвижки',
        err26: '26 - Команда на закрытие задвижки',

        magnetTime: 'Длительность ошибки воздействия магнита',
        flowTime: 'Длительность ошибки выхода за границы расхода',
        tempTime: 'Длительность ошибки выхода за границы температуры',

        // Авторизация
        signIn: 'Авторизация',
        ip: 'IP локального сервера',
        login: 'Логин',
        password: 'Пароль',
        enter: 'Войти',

        // Подсказки
        tooltipExit: 'Выйти',
        tooltipGroupPrint: 'Групповой отчет',
        tooltipGroupTask: 'Групповая задача',
        tooltipEditDroup: 'Редактирование групп',
        tooltipAccount: 'Личные кабинеты',
        tooltipLink: 'Привязка группы счетчиков к пользователю',
        tooltipUpdate: 'Обновить ПО счетчика',
        tooltipUpdateBoot: 'Обновить бутлоадер',
        tooltipService: 'Сервис',
        tooltipResetArchive: 'Запросить архив за последний день',
        tooltipDelete: 'Удалить',
        tooltipMoveToArch: 'Перевести в архив',
        tooltipAdd: 'Добавить',
        tooltipSave: 'Сохранить',
        tooltipBack: 'Назад',
        tooltipLook: 'Просмотр',
        tooltipEdit: 'Редактировать',
        tooltipArshin: 'Подтянуть из АРШИН',
        tooltipDeleteTask: 'Отменить задачу',
        tooltipDeleteUpdate: 'Отменить обновление',
        tooltipInfo: 'Информация',
        tooltipPrint: 'Отчеты',
        tooltipChart: 'Графики',
        tooltipUploadArch: 'Загрузка архива',
        tooltipSetup: 'Настройки',
        tooltipPdf: 'Сохранить в PDF',
        tooltipExcel: 'Сохранить в Excel',
        tooltipMain: 'Главное меню',
        tooltipToken: 'Токены для приложения',
        tooltipAddToken: 'Создать токен',
        tooltipAddAppId: 'Создать пользователя',
        tooltipAddGroupId: 'Создать группу для батареек',
        tooltipLog: 'Журнал сервера',
        tooltipTelegram: 'Привязка Telegram',
        tooltipOn: 'Включить',
        tooltipOff: 'Отключить',

        // Сервис
        headerServiceDeviceNum: 'Зав. номер',
        headerServiceImei: 'IMEI',
        headerServiceHolder: 'Потребитель',
        headerServiceVoltBatMain: 'Vосн., В',
        headerServiceVoltBatReserv: 'Vрез., В',
        headerServiceVoltProc: 'Vпроц, В',
        headerServiceTempProc: 'Tпроц, °С',
        headerServiveFwVer: 'ПО счетчика',
        headerServiveBootVer: 'Бутлоадер',
        headerServiveBoardVer: 'Плата',
        headerServiveActualPoVer: 'Актуальная версия:',
        headerServiveActualFwVer: 'ПО счетчика',
        headerServiveActualBootVer: 'бутлоадер',
        headerServiceGprsSendCnt: 'LTE отп.',
        headerServiveSignalLevel: 'LTE сиг.',
        headerServiceTimeLeft: 'Заряд',
        headerServiceBatDepDate: 'Депас. бат.',
        headerServiveRestartCount: 'Рестарт',
        uploadTransportFile: 'Добавьте транспортный файл',
        uploadArch: 'Загрузка архива',

        // Токен
        headerTokenAppId: 'APP ID',
        headerTokenName: 'Пользователь',
        headerTokenId: 'Токен',
        headerTokenIdType: 'Тип токена',
        headerTokenCreateDate: 'Создан',
        headerTokenDateTo: 'Действует до',
        textTokenAppId: 'APP ID',
        textTokenName: 'Пользователь',
        headerTokenOrganization: 'Организация',
        textTokenOrganization: 'Организация',

        // Локальный сервер
        headerServerId: 'ID локального сервера',
        headerServerName: 'Идентификатор',
        headerServerOrganization: 'Организация',
        headerServerDateFrom: 'Дата активации',
        headerServerDateTo: 'Действует до',
        textServerId: 'ID сервера',
        textServerName: 'Идентификатор',
        textServerOrganization: 'Организация',

        // Лицензия устройств
        headerLicenseImei: 'IMEI',
        headerLicenseProcId: 'ID процессора',
        headerLicenseDeviceNum: 'Заводской номер',
        headerLicenseDeviceType: 'Типоразмер',
        headerLicenseRegKey: 'Регистрационный ключ',
        headerLicenseDatetime: 'Дата активации',
        headerLicenseCnt: 'Количество',
        headerLicenseName: 'Пользователь',
        headerLicenseNote: 'Примечание',

        // Завода страница
        serviceTokenMenu: 'Сервисные токены',
        factoryTokenMenu: 'Токен производителя',
        passwordsMenu: 'Динамические пароли',
        batteryActivateMenu: 'Активация батарейки',
        localServer: 'Активация локального сервера',
        deviceLicense: 'Результаты заводской настройки',

        // Батарейка
        headerBatteryGroupId: 'Идентификатор партии',
        headerBatteryName: 'Обозначение партии',
        headerBatteryNote: 'Примечание',
        headerBatteryDatetime: 'Дата создания',
        headerBatteryImei: 'IMEI НЕО',
        headerBatteryDeviceNum: 'Заводской номер НЕО',
        headerBatteryQrCode: 'Идентификатор батарейки',
        headerBatteryDatetimePair: 'Дата активации',
        textBatteryName: 'Обозначение партии',
        textBatteryNote: 'Примечание',
        batteryActivate: 'Активация батареек',
        textBatteryCnt: 'Количество QR-кодов',
        headerBatteryStartBat: 'Стартовое значение, %',

        // Пароли
        devicePasswords: 'Пароли устройств',
        headerDevicePassowrdsDeviceNum: 'Заводской номер',
        headerDevicePassowrdsPassword: 'Пароль',
        headerDevicePassowrdsImei: 'IMEI',
        headerDevicePassowrdsChangedDate: 'Дата создания',

        // Главное окно
        dashboard: 'Приборная панель',
        prometricaRool: '© 2024 ООО "ПроМетрика". Все права защищены',
        search: 'Поиск...',
        flowSystem: 'Счетчиков в системе',
        activeFlow: 'Активных заданий',
        flowWarning: 'Счетчиков с тревогой',
        flowConnect: 'Потеря связи',
        flowUpdate: 'Задано обновление',
        flowArchive: 'В архиве',
        flowSert: 'Истекает поверка',
        flowBattery: 'Замена батарейки',
        flowLog: 'Журнал сервера',
        flowSearch: 'Результаты поиска',
        service: 'Сервис',
        token: 'Токены для приложения',
        shows: 'Показания, м³',
        flowVale: 'Закрыта задвижка',

        users: 'Пользователи',
        tokens: 'Токены',

        // Окно подтверждения
        confirmDeleteFlowmeter:
          'Вы действительно хотите удалить данный счетчик?',
        confirmDeleteUser:
          'Вы действительно хотите удалить данного пользователя?',
        confirmDeleteGroup: 'Вы действительно хотите удалить данную группу?',
        confirmWindowTitle: 'Требуется подтверждение действия',
        cancel: 'Отмена',
        delete: 'Удалить',

        // Список приборов
        headerListPres: 'Абс. давл., кПа',
        headerListTemp: 'Темп., °C',
        headerListComp: 'Коэф. сжим.',
        headerListSumVolSt: 'Объем ст., м³',
        taskLogUser: 'Сформирован',
        taskDescr: 'Задание',

        // Окно архивов и журналов
        report: 'Отчеты',
        headerArchDatetime: 'Дата',
        headerArchPres: 'Абс. давл., кПа',
        headerArchTemp: 'Темп., °C',
        headerArchComp: 'Коэф. сжим.',
        headerArchVolSt: 'Объем ст., м³',
        headerArchVolErrSt: 'Возм. объем ст., м³',
        headerArchSumVolSt: 'Накоп. объем ст., м³',
        headerArchSumVolErrSt: 'Накоп. возм. объем ст., м³',
        headerArchEvents: 'События',
        headerEventsDatetime: 'Дата и время',
        headerEventsCode: 'Источник',
        headerEventsOldValue: 'Старое значение',
        headerEventsNewValue: 'Новое значение',
        headerEventsStatus: 'Статус',
        headerChangesDatetime: 'Дата и время',
        headerChangesCode: 'Параметр',
        headerChangesOldValue: 'Старое значение',
        headerChangesNewValue: 'Новое значение',

        // Общее
        flowMeter: 'Счетчик',
        group: 'Группа',

        // Групповой отчет
        groupReport: 'Групповой отчет',
        reportType: 'Тип отчета',
        documentFormat: 'Формат документа',
        reportPeriod: 'Отчетный период',
        day: 'День',
        month: 'Месяц',
        year: 'Год',
        date: 'Дата',
        beginPeriod: 'Начало периода',
        endPeriod: 'Конец периода',
        form: 'Сформировать',
        hourArchive: 'Часовой',
        dayArchive: 'Суточный',
        monthArchive: 'Месячный',
        eventsJournal: 'События',
        changesJournal: 'Изменения',

        hourArchiveShort: 'Часовой',
        dayArchiveShort: 'Суточный',
        monthArchiveShort: 'Месячный',
        eventsJournalShort: 'События',
        changesJournalShort: 'Изменения',

        // Групповая задача
        groupTask: 'Групповая задача',
        taskListEmpty: 'Список задач пуст',
        taskList: 'Список задач для отправки',
        taskDone: 'Задать',
        setup: 'Настройка',
        value: 'Значение',

        // Редактирвоание групп
        groupEdit: 'Редактирование групп',
        groupList: 'Список групп',
        groupListEmpty: 'Список групп пуст',
        save: 'Сохранить',
        name: 'Наименование',
        notChoose: 'Не выбрана',

        // Информация и настройки
        titleUpp: 'Условно-постоянные и подстановочные значения',
        titleContr: 'Договорные значения',
        titleServer: 'Настройки сервера',
        titleOther: 'Другие настройки',
        titleControl: 'Действия при срабатывании контроля',
        titleSystem: 'Системные настройки',

        enableValve: 'Команда задвижке',
        showVolumeRu: 'Показывать рабочий объем',
        enableFlowMin: 'Действие при выходе за минимальный расход',
        password1: 'Пароль пользователя',
        password2: 'Пароль поставщика',
        resetMagnet: 'Сброс ошибки воздейсвтия магнита',
        resetBox: 'Сброс ошибки открытия основного отсека',
        resetFlowMax: 'Сброс ошибки максимального расхода',
        resetTempLimit: 'Сброс ошибки предела контроля температуры',
        resetAdcd: 'Сброс ошибки измерительного модуля',
        resetCalibErr: 'Сброс ошибки калибровки температуры',
        resetBox2: 'Сброс ошибки открытия батарейного отсека',
        resetMemmory: 'Сброс ошибки памяти EEPROM/FLASH',
        resetFlowMin: 'Сброс ошибки выхода за минимальный расход',
        resetBattery: 'Сброс остаточного заряда',
        syncdatetime: 'Синхронизировать время',
        getBalance: 'Запросить баланс сим-карты',
        balance: 'Баланс сим-карты',
        operator: 'Оператор',
        gmt: 'Часовой пояс',
        connectTime: 'Время выхода на связь по времени счетчика (час)',
        edit: 'Редактировать',
        setups: 'Настройки',
        holder: 'Потребитель',
        address: 'Адрес установки',
        deviceType: 'Типоразмер',
        deviceNum: 'Заводской номер',
        sertDate: 'Дата поверки',
        constPres: 'Условно-постоянное значение абсолютного давления, кПа',
        constKomp: 'Условно-постоянное значение коэффициента сжимаемости',
        tempMeasPeriod: 'Период измерения температуры, с',
        contrVal:
          'Договорное значение расхода в стандартных условиях (С.У.), м³/ч',
        contrHour: 'Контрактный час',
        contrDay: 'Контрактный день',
        dispOn: 'Дисплей всегда включен',
        dispRotate: 'Автоматическая прокрутка меню дисплея',
        minTempLimit: 'Нижняя граница контроля температуры, °C',
        constMinTemp:
          'Подстановочное значение темп. при выходе за нижнюю границу контроля, °C',
        actionMinTemp:
          'Действие при выходе температуры за нижний предел контроля',
        maxTempLimit: 'Верхняя граница контроля температуры, °C',
        constMaxTemp:
          'Подстановочное значение темп. при выходе за верхнюю границу контроля, °C',
        actionMaxTemp:
          'Действие при выходе температуры за верхний предел контроля',
        actionMagnet: 'Действие при воздействии магнитом на счетный механизм',
        actionMinFlow: 'Действие при выходе за минимальный расход счетчика',
        actionMaxFlow: 'Действие при выходе за максимальный расход счетчика',
        server:
          'Адрес сервера и порт (в формате [ip-адрес]:[порт], например 192.168.1.1:8000)',
        signLevel: 'Уровень сигнала',
        minSign: 'Минимальный уровень сигнала',
        minGprsTemp: 'Минимальная температура отправки LTE',
        repeatCount: 'Количество повторов отправки',
        contract: 'Договор',
        inn: 'ИНН',
        persAcc: 'Лицевой счет',
        kategory: 'Категория',
        phone: 'Телефон',
        email: 'Почта',
        info: 'Информация',
        restartCount: 'Рестарт',
        infoDevice: 'Информация по счетчику',
        imei: 'IMEI',
        procId: 'ID процессора',
        fwVers: 'Версия ПО',
        fwDate: 'Дата версии ПО',
        voltBat: 'Напряжение основной батарейки, В',
        voltResBat: 'Напряжение резервной батарейки, В',
        voltProc: 'Напряжения процессора, В',
        tempProc: 'Температура процессора, °С',
        timeLeft: 'Остаточный заряд, %',
        flowMeterValues: 'Показания счетчика',
        temp: 'Температура, °С',
        pres: 'Абсолютное давление, кПа',
        komp: 'Коэффициент сжимаемости',
        sumVol: 'Накопленный рабочий объем, м³',
        sumVolSt: 'Накопленный объем (ст. усл.), м³',
        sumVolErrSt: 'Накопленный возм. объем (ст. усл.), м³',
        volSt: 'Объем (ст. усл.), м³',
        volErrSt: 'Возм. объем (ст. усл.), м³',
        events: 'События',
        nowErrors: 'Текущие ошибки',
        memmoryErrors: 'Ошибки из памяти',
        datetime: 'Дата и время',
        plombNum: 'Электронная пломба',
        plombBroken: 'Пломба сорвана',
        stats: 'Статистика',
        valve: 'Задвижка',
        valveStatus: 'Состояние задвижки',
        timeTotal: 'Общее время работы, ч',
        timeNewBat: 'Время работы после замены батареи, ч',
        timeBle: 'Общее время работы Bluetooth 1, ч',
        timeBleNew: 'Время работы Bluetooth 1 после замены батареи, ч',
        timeBleSleep: 'Общее время работы Bluetooth 2, ч',
        timeBleNewSleep: 'Время работы Bluetooth 2 после замены батареи, ч',
        timeDisp: 'Общее время работы LCD, ч',
        timeDispNew: 'Время работы LCD после замены батареи, ч',
        timeGprs: 'Общее время работы LTE, ч',
        timeGprsNew: 'Время работы LTE после замены батареи, ч',
        connect: 'Подключение',
        dateCreate: 'Первое',
        dateLastCon: 'Последнее',
        dateNextCon: 'Следующее',
        actionJournalOnly: 'Только запись в журнал',
        actionContrVal: 'Использовать договорное значение расхода в С.У.',
        actionConstTemp: 'Использовать подстановочное значение температуры',
        dispOnStatusOff: 'Отключен',
        dispOnStatusOn: 'Включен',
        dispRotateStatusOff: 'Отключена',
        dispRotateStatusOn: 'Включена',
        people: 'Физическое лицо',
        noError: 'Нет ошибок',
        noEvents: 'Нет событий',
        factory: 'Юридическое лицо',
        showVolumeOff: 'Нет',
        showVolumeOn: 'Да',
        batDepDate: 'Дата последней депассивации',
        batDepResult: 'Статус депассивации',
        batDepTime: 'Длительность последней депассивации, с',
        gprsSendAll: 'Количество отправок LTE после замены батареи',
        gprsSendOk: 'Количество успешных отправок LTE после замены батареи',
        gprsSendErr: 'Количество неудачных отправок LTE после замены батареи',
        enableFlowMinOff: 'Нет действий',
        enableFlowMinOn: 'Использовать минимальное значение расхода в С.У.',

        valveOff: 'Закрыть',
        valveOn: 'Открыть',

        valveType: 'Тип задвижки',
        valveTypeOff: 'Не установлена',
        valveTypeOne: 'Клапан - одинарный',
        valveTypeTwo: 'Клапан - двойной',
        valveTypeShar: 'Шаровая задвижка',

        valvePol: 'Полярность задвижки',
        valvePolPryam: 'Прямая',
        valvePolObrat: 'Обратная',

        telegramId: 'Telegram ID',
        telegramService: 'Сервисный режим',
        telegramName: 'Пользователь',
        valveStatusUndef: 'Недоступно',
        valveStatus0: 'Не определено',
        valveStatus1: 'Открывается',
        valveStatus2: 'Ошибка открытия',
        valveStatus3: 'Открыто',
        valveStatus4: 'Закрывается',
        valveStatus5: 'Ошибка закрытия',
        valveStatus6: 'Закрыто',
        valveStatus7: 'Ожидание нажатия кнопки',
        valveStatus8: 'Подготовка закрытия',
        valveStatus9: 'Подготовка открытия',
        valveStatus10: 'Открыто (проверка расхода)',
        valveStatus11: 'Закрывается (утечка)',
        valveStatus12: 'Ошибка закрытия (утечка)',
        valveStatus13: 'Закрыто (утечка)',
        valveStatus14: 'Подготовка закрытия (утечка)',

        depStatsu0: 'Не проведена',
        depStatsu1: 'Старт депассивации',
        depStatsu2: 'Депассивация в процессе',
        depStatsu3: 'Депассивация не требуется',
        depStatsu4: 'Депассивация завершилась по напряжению (стало выше 3.1 В)',
        depStatsu5:
          'Депассивация завершилась по времени (работало 20 минут, но не вышли на напряжение выше 3.1 В)',

        // График
        chart: 'Графики',
        lineChart: 'Линейный график',
        diagramma: 'Диаграмма',
        areaChart: 'Закрашенный график',
        volumeChart: 'Объем ст., м³',
        volumeSumChart: 'Объем ст. (нарастание), м³',
        tempChart: 'Температура, °C',
        dateTimeChart: 'Дата и время',

        // Личные кабинеты
        accountEdit: 'Личные кабинеты',
        loginName: 'Имя пользователя',
        create: 'Создать',
        read: 'Чтение',
        readWrite: 'Чтение и запись',
        otherService: 'Сторонний сервис',
        admin: 'Администратор',
        root: 'Уровень доступа',
        action: 'Действие',
        userLink: 'Привязка группы счетчиков к пользователю',
        flowmeterGroupId: 'Идентифкатор группы счетчиков',

        // Статусы API
        successAddGroupId: 'Обозначение партии батареек добавлено',
        successAddQrCode: 'QR-коды добавлены',
        errotNotFoundAccount:
          'Такого личного кабинет нет. Проверьте правильность IP адреса локального сервера, а также логин и пароль',
        errotServer:
          'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже',
        successAddUser: 'Пользователь добавлен',
        errorLoginConflict: 'Такой пользователь существует',
        successDeletAccount: 'Пользователь удален',
        errorGroupConflict: 'Такая группа существует',
        successDeletGroup: 'Группа удалена',
        successAddGroup: 'Группа добавлена',
        needUpdate: 'ВНИМАНИЕ! Необходимо обновить локальный сервер до версии',

        // Логи
        logDeleteUser: 'Удален пользователь',
        logLogout: 'Выход из кабинета',
        logSignIn: 'Авторизация',
        logSignUp: 'Добавлен новый пользователь',
        logAddGroup: 'Добавлена новая группа',
        logDeleteGroup: 'Группа удалена',
        logAddGroupTask: 'Добавлена групповая задача',
        logUpdtateUserFlowmeterGroupId:
          'Обновлена информация привязки пользователя к счетчикам',
        logDeleteDevice: 'Удален счетчик',
        logMoveToArch: 'Счетчик переведен в архив',
        logUpdateInfo: 'Обновление информации о счетчике',
        logUpdateSetup: 'Обновление задачи счетчика',
        logDeleteSetup: 'Удаление задачи счетчика',
        logUpdateFw: 'Задано обновление счетчика',
        logDeleteUpdateFw: 'Отменено обновление счетчика',
        logAddAppId: 'Добавлено приложение (пользователь)',
        logDeleteAppId: 'Удалено приложение (пользователь)',
        logDeleteTokenId: 'Удален токен для приложения',
        logAddToken: 'Создан токен для приложения',
        logAddTelegramId: 'Привязан Telegram ID',
        logDeleteTelegramId: 'Удален Telegram ID',
        logChangeServiceTelegramId: 'Изменен сервисный режим Telegram ID',

        // Надпись под полем ввода
        enterValue: 'Введите значение',

        outSpan: 'не соответствует диапазону',
        from: 'от',
        to: 'до',

        // Описание заводской номер сервис
        lics: 'Лицензия',
        flowMin: 'Минимальное значение расхода в С.У. м³/ч',
        flowMax: 'Максимальное значение расхода в С.У., м³/ч',
        flowSense: 'Порог чувствительности счетного механизма, м³/ч',
        kimpA: 'Коэф. А объема одного оборота счетчика',
        kimpB: 'Коэф. B объема одного оборота счетчика',
        kimpC: 'Коэффициент 1',
        rtcReal: 'Измеренное значение опорной частоты RTC, Гц',
        tempIntOffset: 'Смещение температуры платы, °C',
        calResist: 'Калибровочное сопротивление, Ом',
        gprsMaxSend: 'Максимальное количество отправок по LTE',
        maxBattery: 'Максимальная емкость основной батареи, мкА/ч',
        startBat: 'Стартовое значение остаточного заряда, %',
        tokBase: 'Базовый ток потребления (дисплей выключен), мкА',
        tokBleActiv: 'Ток потребления Bluetooth в режиме соединения, мкА',
        tokBleSleep: 'Ток потребления Bluetooth в режиме сна, мкА',
        toDisp: 'Базовый ток потребления (дисплей включен), мкА',
        pinCode: 'ПИН-код сим-карты',
        ussdCommand: 'USSD команда запроса баланса сим-карты',

        vsnh: 'Объем (ст. усл.) тек. час., м³',
        vesnh: 'Возм. объем (ст. усл.) тек. час, м³',
        vwnh: 'Объем (рабочий) тек. час, м³',

        vslh: 'Объем (ст. усл.) пр. час., м³',
        veslh: 'Возм. объем (ст. усл.) пр. час, м³',
        vwlh: 'Объем (рабочий) пр. час, м³',

        vsnd: 'Объем (ст. усл.) тек. день., м³',
        vesnd: 'Возм. объем (ст. усл.) тек. день, м³',
        vwnd: 'Объем (рабочий) тек. день, м³',

        vsld: 'Объем (ст. усл.) пр. день., м³',
        vesld: 'Возм. объем (ст. усл.) пр. день, м³',
        vwld: 'Объем (рабочий) пр. день, м³',

        vsnm: 'Объем (ст. усл.) тек. месяц., м³',
        vesnm: 'Возм. объем (ст. усл.) тек. месяц, м³',
        vwnm: 'Объем (рабочий) тек. месяц, м³',

        vslm: 'Объем (ст. усл.) пр. месяц., м³',
        veslm: 'Возм. объем (ст. усл.) пр. месяц, м³',
        vwlm: 'Объем (рабочий) пр. месяц, м³',
      },
    },
  },
  lng: getSelectedLng(),
  fallbackLng: LANGUAGE_RUS,
});

export default i18n;
